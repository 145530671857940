import { ApiInterface } from '@app/@core/services/api/api.interface';
import { AbstractCrudInterface } from '@app/@core/services/crud/abstract-crud.interface';
import { Observable } from 'rxjs';

export abstract class AbstractCrudService implements AbstractCrudInterface {

  protected supportedMethods?: string[];

  protected constructor(
    protected api: ApiInterface,
  ) {
  }

  protected _urlNameSpace: string;

  get urlNameSpace(): string {
    return this._urlNameSpace;
  }

  protected callApiIfSupported(method: string, successCallback: Observable<any>): Observable<any> {
    if (this.checkMethodIsSupported(method)) {
      return successCallback;
    } else {
      return this.handleUnsupportedRequest();
    }
  }

  protected checkMethodIsSupported(methodName: string): boolean {
    return (typeof this.supportedMethods === 'undefined' || !this.supportedMethods || this.supportedMethods.includes(methodName));
  }

  // Call this method when requst is not supported (e.g. in override of abstract method)
  protected handleUnsupportedRequest(): Observable<any> {
    return new Observable(observer => {
      this.handleUnsupportedError();
      observer.error();
      observer.complete();
    });
  }

  private handleUnsupportedError() {
    console.error('This request is not supported!');
  }

}
