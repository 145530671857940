/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blank.layout.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@nebular/theme/components/layout/layout.component.ngfactory";
import * as i3 from "@nebular/theme/components/layout/layout.component";
import * as i4 from "@nebular/theme/services/theme.service";
import * as i5 from "@nebular/theme/services/spinner.service";
import * as i6 from "@nebular/theme/theme.options";
import * as i7 from "@nebular/theme/services/direction.service";
import * as i8 from "@nebular/theme/services/scroll.service";
import * as i9 from "@nebular/theme/services/ruler.service";
import * as i10 from "@nebular/theme/components/layout/restore-scroll-top.service";
import * as i11 from "@nebular/theme/components/cdk/adapter/overlay-container-adapter";
import * as i12 from "@angular/router";
import * as i13 from "./blank.layout";
var styles_BlankLayoutComponent = [i0.styles];
var RenderType_BlankLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlankLayoutComponent, data: {} });
export { RenderType_BlankLayoutComponent as RenderType_BlankLayoutComponent };
export function View_BlankLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "nb-layout", [], [[2, "window-mode", null], [2, "with-scroll", null], [2, "with-subheader", null], [2, "overlay-scroll-block", null]], [["window", "scroll"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_NbLayoutComponent_0, i2.RenderType_NbLayoutComponent)), i1.ɵdid(1, 4374528, null, 0, i3.NbLayoutComponent, [i4.NbThemeService, i5.NbSpinnerService, i1.ElementRef, i1.Renderer2, i6.NB_WINDOW, i6.NB_DOCUMENT, i1.PLATFORM_ID, i7.NbLayoutDirectionService, i8.NbLayoutScrollService, i9.NbLayoutRulerService, i10.NbRestoreScrollTopHelper, i11.NbOverlayContainerAdapter], null, null), (_l()(), i1.ɵeld(2, 0, null, 3, 3, "nb-layout-column", [], [[2, "left", null], [2, "start", null]], null, null, i2.View_NbLayoutColumnComponent_0, i2.RenderType_NbLayoutColumnComponent)), i1.ɵdid(3, 49152, null, 0, i3.NbLayoutColumnComponent, [], null, null), (_l()(), i1.ɵeld(4, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i12.RouterOutlet, [i12.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).windowModeValue; var currVal_1 = i1.ɵnov(_v, 1).withScrollValue; var currVal_2 = i1.ɵnov(_v, 1).withSubheader; var currVal_3 = i1.ɵnov(_v, 1).overlayScrollBlock; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 3).leftValue; var currVal_5 = i1.ɵnov(_v, 3).startValue; _ck(_v, 2, 0, currVal_4, currVal_5); }); }
export function View_BlankLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-blank-layout", [], null, null, null, View_BlankLayoutComponent_0, RenderType_BlankLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i13.BlankLayoutComponent, [], null, null)], null, null); }
var BlankLayoutComponentNgFactory = i1.ɵccf("app-blank-layout", i13.BlankLayoutComponent, View_BlankLayoutComponent_Host_0, {}, {}, []);
export { BlankLayoutComponentNgFactory as BlankLayoutComponentNgFactory };
