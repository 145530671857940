import { ApiService } from '@app/@core/services/api/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../@core/services/api/api.service";
var RegistrationService = /** @class */ (function () {
    function RegistrationService(api) {
        this.api = api;
    }
    RegistrationService.prototype.submit = function (model) {
        return this.api.post('/users/register', {
            captcha: model.captcha,
            email: model.email,
            full_name: model.fullName,
            password: model.password,
            phone: model.phone,
            company_name: model.companyName,
            company_id: model.companyId,
            company_vat_id: model.companyVatId,
            company_street: model.companyStreet,
            company_city: model.companyCity,
            company_postal_code: model.companyPostalCode,
            company_country_abbrev: model.companyCountryAbbrev
        });
    };
    RegistrationService.ngInjectableDef = i0.defineInjectable({ factory: function RegistrationService_Factory() { return new RegistrationService(i0.inject(i1.ApiService)); }, token: RegistrationService, providedIn: "root" });
    return RegistrationService;
}());
export { RegistrationService };
