import { environment } from '@/environments/environment';
import * as i0 from "@angular/core";
var RecaptchaResolverService = /** @class */ (function () {
    function RecaptchaResolverService() {
        this.recaptchaKey = environment.recaptchaKey;
        // Do not require Recaptcha validation on localhost for dev purposes, unless key is listed in env variables
        this.recaptchaEnabled = (environment.production || environment.recaptchaKey.length > 0);
    }
    RecaptchaResolverService.ngInjectableDef = i0.defineInjectable({ factory: function RecaptchaResolverService_Factory() { return new RecaptchaResolverService(); }, token: RecaptchaResolverService, providedIn: "root" });
    return RecaptchaResolverService;
}());
export { RecaptchaResolverService };
