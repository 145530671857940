import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractEntitySchemaInterface } from '@app/@core/schema/abstract-entity.schema.interface';
import { EntityNavigatorService } from '@app/@core/services/entity-navigator/entity-navigator.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-buttons-list-page',
  templateUrl: './buttons-list-page.component.html',
  styleUrls: ['./buttons-list-page.component.scss']
})
export class ButtonsListPageComponent {

  @Input() schema: AbstractEntitySchemaInterface;
  @Input() isLoading = false;
  @Output() refresh = new EventEmitter<string>();

  constructor(
    private navigator: EntityNavigatorService,
    public translate: TranslateService,
  ) {
  }

  onNew(): void {
    if (this.schema.allowNew) {
      this.navigator.new(this.schema);
    }
  }

  onPrint(): void {
    window.print();
  }

}
