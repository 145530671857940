import UserResponse from '@/app/pages/users/model/user-response.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/@core/model/user.model';
import { AuthService } from '@app/@core/services/auth/auth.service';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { Company } from '@app/pages/company/model/company.model';
import { NbMenuItem, NbMenuService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  userResponse: UserResponse;
  user: User;
  company: Company;
  menuItems: NbMenuItem[] = [];
  isLoading = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private userMeService: UserMeService,
    private notify: NotificationsService,
    private translate: TranslateService,
    private menuService: NbMenuService,
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    const user$ = this.userMeService.getUserInfo(true);
    user$.subscribe((data) => {
      this.userResponse = data;
      this.user = data.user;
      this.company = data.company;
      this.loadMenuItems();
      this.menuService.onItemClick()
        .subscribe((event) => {
          if (event.tag === 'userMenu' && event.item.target === 'sign-out') {
            this.onLogOut();
          }
        });
      this.isLoading = false;
    });
  }

  onLogOut() {
    const logoutResult = this.auth.logOut();
    logoutResult.subscribe(
      () => {
        this.router.navigate(['/login']);
      },
      error => {
        this.notify.error(error['error'].message);
        console.error(error);
      });
  }

  private loadMenuItems() {
    const menuItems = this.getMenuItems();

    this.menuItems = [];
    for (const each of menuItems) {
      this.menuItems.push(each);

      this.translate.stream(each.title).subscribe(res => {
        each.title = res;
      });
      if (each.hasOwnProperty('children')) {
        for (const eachChild of each.children) {
          this.translate.stream(eachChild.title).subscribe(res => {
            eachChild.title = res;
          });
        }
      }
    }
  }

  private getMenuItems(): NbMenuItem[] {
    return [
      {
        title: 'page.profile.titleShort',
        link: '/profile',
        icon: 'fa fa-user',
      },
      {
        title: 'page.company.my',
        link: `/company/${this.company.id}/`,
        icon: 'fa fa-city'
      },
      {
        title: 'button.log_out',
        target: 'sign-out',
        icon: 'fa fa-sign-out-alt',
      },
    ];
  }


}
