import { AuthService } from '@/app/@core/services/auth/auth.service';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureLevelService } from '@app/@core/services/feature-level/feature-level.service';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { LayoutService } from '@app/@core/utils';
import { NbSidebarService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
var SideNavComponent = /** @class */ (function () {
    function SideNavComponent(router, features, translate, auth, userMeService, sidebarService, layoutService) {
        this.router = router;
        this.features = features;
        this.translate = translate;
        this.auth = auth;
        this.userMeService = userMeService;
        this.sidebarService = sidebarService;
        this.layoutService = layoutService;
        this.menuItems = [];
    }
    SideNavComponent.prototype.ngOnInit = function () {
        this.loadMenuItems();
    };
    SideNavComponent.prototype.navigate = function (url) {
        return this.router.navigate([url]);
    };
    SideNavComponent.prototype.loadMenuItems = function () {
        var _this = this;
        this.getMenuItems()
            .then(function (menuItems) {
            _this.menuItems = [];
            var _loop_1 = function (each) {
                _this.menuItems.push(each);
                _this.translate.stream(each.title).subscribe(function (res) {
                    each.title = res;
                });
                if (each.hasOwnProperty('children')) {
                    var _loop_2 = function (eachChild) {
                        _this.translate.stream(eachChild.title).subscribe(function (res) {
                            eachChild.title = res;
                        });
                    };
                    for (var _i = 0, _a = each.children; _i < _a.length; _i++) {
                        var eachChild = _a[_i];
                        _loop_2(eachChild);
                    }
                }
            };
            for (var _i = 0, menuItems_1 = menuItems; _i < menuItems_1.length; _i++) {
                var each = menuItems_1[_i];
                _loop_1(each);
            }
        });
    };
    SideNavComponent.prototype.getMenuItems = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.userMeService.getUserInfo(false)
                .subscribe(function (user) {
                if (user.companyUser.role.name === 'Inventura') {
                    _this.sidebarService.collapse('menu-sidebar');
                    _this.layoutService.changeLayoutSize();
                    resolve([
                        {
                            title: 'page.stockTaking.title',
                            link: '/stock-taking',
                            icon: 'fa fa-file'
                        }
                    ]);
                }
                else {
                    resolve([
                        {
                            title: 'dashboard.title',
                            link: '/',
                            icon: 'fa fa-home',
                            home: true,
                        },
                        {
                            title: 'branches.title',
                            link: '/branches',
                            icon: 'fa fa-building',
                            hidden: !_this.auth.isAdmin()
                        },
                        {
                            title: 'stocks.title',
                            link: '/stocks',
                            icon: 'fa fa-warehouse',
                        },
                        {
                            title: 'stockCards.title',
                            link: '/stock-cards',
                            icon: 'fa fa-cube'
                        },
                        {
                            title: 'recipes.title',
                            link: '/recipes',
                            icon: 'fa fa-book'
                        },
                        {
                            title: 'suppliers.title',
                            link: '/suppliers',
                            hidden: !_this.features.isAvailable(_this.features.LEVEL_DEV),
                            icon: 'fa fa-truck'
                        },
                        {
                            title: 'users.title',
                            link: '/users',
                            icon: 'fa fa-user',
                            hidden: !_this.auth.isAdmin()
                        },
                        {
                            title: 'page.stats.title',
                            link: '/stats',
                            hidden: !_this.features.isAvailable(_this.features.LEVEL_DEV),
                            icon: 'fa fa-chart-bar'
                        },
                        {
                            title: 'page.reports.title',
                            link: '/reports',
                            hidden: !_this.features.isAvailable(_this.features.LEVEL_DEV),
                            icon: 'fa fa-file'
                        },
                        {
                            title: 'page.stockTaking.title',
                            link: '/stock-taking',
                            icon: 'fa fa-file'
                        },
                        {
                            title: 'externalLink.groupTitle',
                            group: true,
                        },
                        {
                            title: 'externalLink.cinkCloud',
                            url: 'https://cink.cloud/',
                            target: '_blank',
                            icon: 'fa fa-dollar-sign'
                        },
                    ]);
                }
            });
        });
    };
    return SideNavComponent;
}());
export { SideNavComponent };
