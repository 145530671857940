/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@nebular/theme/components/button/button.component.ngfactory";
import * as i4 from "@nebular/theme/components/button/button.component";
import * as i5 from "./not-found.component";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "not-found"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " (", " 404)"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["hero", ""], ["nbButton", ""]], [[2, "btn-xsmall", null], [2, "btn-small", null], [2, "btn-medium", null], [2, "btn-large", null], [2, "btn-primary", null], [2, "btn-info", null], [2, "btn-success", null], [2, "btn-warning", null], [2, "btn-danger", null], [2, "btn-rectangle", null], [2, "btn-round", null], [2, "btn-semi-round", null], [2, "btn-hero", null], [2, "btn-outline", null], [1, "aria-disabled", 0], [2, "btn-disabled", null], [1, "tabindex", 0], [2, "btn-full-width", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onGoBack() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_NbButtonComponent_0, i3.RenderType_NbButtonComponent)), i1.ɵdid(9, 49152, null, 0, i4.NbButtonComponent, [i1.Renderer2, i1.ElementRef], { setHero: [0, "setHero"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 0, "i", [["class", "fa fa-arrow-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, 0, ["\u00A0 ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_21 = ""; _ck(_v, 9, 0, currVal_21); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("page.notFound.title")); var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 4).transform("page.notFound.error")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("page.notFound.text")); _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 9).xsmall; var currVal_4 = i1.ɵnov(_v, 9).small; var currVal_5 = i1.ɵnov(_v, 9).medium; var currVal_6 = i1.ɵnov(_v, 9).large; var currVal_7 = i1.ɵnov(_v, 9).primary; var currVal_8 = i1.ɵnov(_v, 9).info; var currVal_9 = i1.ɵnov(_v, 9).success; var currVal_10 = i1.ɵnov(_v, 9).warning; var currVal_11 = i1.ɵnov(_v, 9).danger; var currVal_12 = i1.ɵnov(_v, 9).rectangle; var currVal_13 = i1.ɵnov(_v, 9).round; var currVal_14 = i1.ɵnov(_v, 9).semiRound; var currVal_15 = i1.ɵnov(_v, 9).hero; var currVal_16 = i1.ɵnov(_v, 9).outline; var currVal_17 = i1.ɵnov(_v, 9).disabled; var currVal_18 = i1.ɵnov(_v, 9).disabled; var currVal_19 = i1.ɵnov(_v, 9).tabbable; var currVal_20 = i1.ɵnov(_v, 9).fullWidth; _ck(_v, 8, 1, [currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20]); var currVal_22 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("page.notFound.goBack")); _ck(_v, 11, 0, currVal_22); }); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 49152, null, 0, i5.NotFoundComponent, [], null, null)], null, null); }
var NotFoundComponentNgFactory = i1.ɵccf("app-not-found", i5.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
