/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/loader/loader.component.ngfactory";
import * as i3 from "../../../../shared/components/loader/loader.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../forms/login-form/login-form.component.ngfactory";
import * as i6 from "../../forms/login-form/login-form.component";
import * as i7 from "../../../../@core/services/auth/auth.service";
import * as i8 from "@angular/router";
import * as i9 from "angular2-notifications";
import * as i10 from "@angular/common";
import * as i11 from "./login-page.component";
var styles_LoginPageComponent = [i0.styles];
var RenderType_LoginPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginPageComponent, data: {} });
export { RenderType_LoginPageComponent as RenderType_LoginPageComponent };
function View_LoginPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoaderComponent, [i4.TranslateService], null, null)], null, null); }
export function View_LoginPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "login-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "login-page__form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-login-form", [], null, null, null, i5.View_LoginFormComponent_0, i5.RenderType_LoginFormComponent)), i1.ɵdid(3, 49152, null, 0, i6.LoginFormComponent, [i7.AuthService, i8.Router, i8.ActivatedRoute, i9.NotificationsService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginPageComponent_1)), i1.ɵdid(5, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_LoginPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-page", [], null, null, null, View_LoginPageComponent_0, RenderType_LoginPageComponent)), i1.ɵdid(1, 114688, null, 0, i11.LoginPageComponent, [i8.ActivatedRoute, i7.AuthService, i9.NotificationsService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginPageComponentNgFactory = i1.ɵccf("app-login-page", i11.LoginPageComponent, View_LoginPageComponent_Host_0, {}, {}, []);
export { LoginPageComponentNgFactory as LoginPageComponentNgFactory };
