import { Component, Input } from '@angular/core';
import { AbstractEntitySchemaInterface } from '@app/@core/schema/abstract-entity.schema.interface';
import { EntityNavigatorService } from '@app/@core/services/entity-navigator/entity-navigator.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-buttons-new-page',
  templateUrl: './buttons-new-page.component.html',
  styleUrls: ['./buttons-new-page.component.scss']
})
export class ButtonsNewPageComponent {

  @Input() schema: AbstractEntitySchemaInterface;
  @Input() isLoading = false;

  constructor(
    public translate: TranslateService,
    private navigator: EntityNavigatorService,
  ) {
  }

  onBackToList(): void {
    this.navigator.list(this.schema);
  }

}
