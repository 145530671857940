<div class="not-found">
  <h2>{{'page.notFound.title'|translate}} ({{'page.notFound.error'|translate}} 404)</h2>

  <p>
    {{'page.notFound.text'|translate}}
  </p>

  <button (click)="onGoBack()" hero nbButton>
    <i class="fa fa-arrow-left"></i>&nbsp;
    {{'page.notFound.goBack'|translate}}
  </button>
</div>
