<div class="left header-container">
  <div class="logo-containter">
    <a (click)="toggleSidebar()" class="navigation" href="#"><i class="nb-menu"></i></a>
    <div (click)="goToHome()" class="logo clickable">
      <img height="45" src="https://www.cinkeet.cz/wp-content/uploads/2017/10/logo-prestashop.png">
    </div>
  </div>
</div>

<div class="header-container">
  <nb-actions class="right" size="large">
    <nb-action>
      <app-user-menu></app-user-menu>
    </nb-action>
    <nb-action *ngIf="!isLoading">
      <nb-select (selectedChange)="onBranchChange($event)" [(ngModel)]="currentBranch.id" class="branch-selector"
                 name="branch"
                 placeholder="{{'placeholder.selectBranch' | translate}}">
        <nb-option *ngIf="companyOption" [value]="companyOption.id">{{companyOption.name}}</nb-option>
        <nb-option-group style="font-weight: 700;" title="{{'branches.title' | translate}}">
          <nb-option *ngFor="let branch of branches; let i = index" [value]="branch.id" style="font-weight: 400;">
            {{branch.name}}
          </nb-option>
        </nb-option-group>
      </nb-select>
    </nb-action>
  </nb-actions>
</div>
