import { Injectable } from '@angular/core';
import { ApiService } from '@app/@core/services/api/api.service';
import { ForgotPasswordFinishModel } from '@app/pages/login/model/forgot-password-finish.model';
import { ForgotPasswordModel } from '@app/pages/login/model/forgot-password.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private api: ApiService) {

  }

  submit(model: ForgotPasswordModel): Observable<any> {
    return this.api.post('/users/forgotten-password', {
      username: model.username,
      captcha: model.captcha
    });
  }

  finish(model: ForgotPasswordFinishModel): Observable<any> {
    return this.api.post('/users/forgotten-password/finish', {
      token: model.token,
      newPassword: model.newPassword,
      captcha: model.captcha
    });
  }

}
