import { Router } from '@angular/router';
import { AuthService } from '@app/@core/services/auth/auth.service';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth/auth.service";
import * as i2 from "@angular/router";
import * as i3 from "../services/user-me/user-me.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(auth, router, userMeService) {
        this.auth = auth;
        this.router = router;
        this.userMeService = userMeService;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        return new Promise(function (resolve) {
            var authenticated = _this.auth.isAuthenticated();
            if (!authenticated) {
                _this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            }
            return _this.userMeService.getUserInfo(true)
                .subscribe(function (companyUser) {
                if (companyUser.companyUser.role.name === 'Inventura') {
                    if (state.url.includes('stock-taking')) {
                        resolve(authenticated);
                    }
                    else {
                        _this.router.navigate(['/stock-taking']);
                    }
                }
                else {
                    resolve(authenticated);
                }
            });
        });
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.AuthService), i0.inject(i2.Router), i0.inject(i3.UserMeService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
