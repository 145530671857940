/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@nebular/theme/components/spinner/spinner.component.ngfactory";
import * as i3 from "@nebular/theme/components/spinner/spinner.component";
import * as i4 from "./loader.component";
import * as i5 from "@ngx-translate/core";
var styles_LoaderComponent = [i0.styles];
var RenderType_LoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
export function View_LoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "nb-spinner", [["size", "xxlarge"], ["status", "success"]], [[2, "xxsmall-spinner", null], [2, "xsmall-spinner", null], [2, "small-spinner", null], [2, "medium-spinner", null], [2, "large-spinner", null], [2, "xlarge-spinner", null], [2, "xxlarge-spinner", null], [2, "active-spinner", null], [2, "disabled-spinner", null], [2, "primary-spinner", null], [2, "info-spinner", null], [2, "success-spinner", null], [2, "warning-spinner", null], [2, "danger-spinner", null]], null, null, i2.View_NbSpinnerComponent_0, i2.RenderType_NbSpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i3.NbSpinnerComponent, [], { message: [0, "message"], setSize: [1, "setSize"], setStatus: [2, "setStatus"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_14 = _co.translate.instant("message.loading"); var currVal_15 = "xxlarge"; var currVal_16 = "success"; _ck(_v, 2, 0, currVal_14, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).xxsmall; var currVal_1 = i1.ɵnov(_v, 2).xsmall; var currVal_2 = i1.ɵnov(_v, 2).small; var currVal_3 = i1.ɵnov(_v, 2).medium; var currVal_4 = i1.ɵnov(_v, 2).large; var currVal_5 = i1.ɵnov(_v, 2).xlarge; var currVal_6 = i1.ɵnov(_v, 2).xxlarge; var currVal_7 = i1.ɵnov(_v, 2).active; var currVal_8 = i1.ɵnov(_v, 2).disabled; var currVal_9 = i1.ɵnov(_v, 2).primary; var currVal_10 = i1.ɵnov(_v, 2).info; var currVal_11 = i1.ɵnov(_v, 2).success; var currVal_12 = i1.ɵnov(_v, 2).warning; var currVal_13 = i1.ɵnov(_v, 2).danger; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); }); }
export function View_LoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i4.LoaderComponent, [i5.TranslateService], null, null)], null, null); }
var LoaderComponentNgFactory = i1.ɵccf("app-loader", i4.LoaderComponent, View_LoaderComponent_Host_0, {}, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
