import { environment } from '@/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiInterface } from '@app/@core/services/api/api.interface';
import { LocalStorageService } from '@app/@core/services/storage/local-storage.service';
import { TOKEN_STORAGE_KEY } from '@app/@core/services/storage/storage-keys';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService implements ApiInterface {
  apiEndpoint: string;
  STATUS_UNAUTHORIZED = 401;
  STATUS_NO_CONTENT = 204;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storage: LocalStorageService,
  ) {
    if (environment.hasOwnProperty('apiMockAll') && environment['apiMockAll'] === true) {
      this.apiEndpoint = environment['apiMockEndpoint'];
    } else {
      this.apiEndpoint = environment.apiEndpoint;
    }
  }

  get(url: string) {
    return this.resolveApiResponse(this.http.get(`${this.apiEndpoint}${url}`));
  }

  post(url: string, options: {} = {}) {
    return this.resolveApiResponse(this.http.post(`${this.apiEndpoint}${url}`, options));
  }

  put(url: string, options: {}) {
    return this.resolveApiResponse(this.http.put(`${this.apiEndpoint}${url}`, options));
  }

  patch(url: string, options: {}) {
    return this.resolveApiResponse(this.http.patch(`${this.apiEndpoint}${url}`, options));
  }

  delete(url: string, options: {}) {
    return this.resolveApiResponse(this.http.delete(`${this.apiEndpoint}${url}`, options));
  }

  resolveApiResponse(data: Observable<any>) {
    return new Observable(observer => {
      data.subscribe(
        response => {
          // TODO: change this to status 204 no content
          if (response === null) {
            observer.next();
          }
          if (response['data'] !== undefined) {
            observer.next(response);
          } else {
            observer.error(response);
          }
          observer.complete();
        },
        error => {
          if (error.status === this.STATUS_UNAUTHORIZED || error.status === 403) {
            this.storage.remove(TOKEN_STORAGE_KEY);
            if (!this.router.url.includes('/login')) {
              this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}});
            }
          }
          observer.error(this.parseErrorResponse(error));
          observer.complete();
        });
    });
  }

  parseErrorResponse(error: string) {
    console.log({error});
    let errorResponse = error['error'];
    if (typeof errorResponse === 'string') {
      errorResponse = {
        error: {message: errorResponse}
      };
    }
    if (errorResponse['error'] === undefined) {
      errorResponse['error'] = {};
    }
    if (errorResponse['error']['message'] === undefined) {
      errorResponse['error']['message'] = 'Undefined error';
    }
    return errorResponse;
  }
}
