import { Component, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-one-column-layout',
  templateUrl: './one-column.layout.html',
  styleUrls: ['./one-column.layout.scss']
})
export class OneColumnLayoutComponent implements OnDestroy {

  currentTheme: string;
  private alive = true;

  constructor(protected themeService: NbThemeService) {
    this.themeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        this.currentTheme = theme.name;
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
