import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Credentials } from '@app/@core/model/credentials.model';
import { AuthService } from '@app/@core/services/auth/auth.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {

  isLoading = false;
  model: Credentials = {};

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationsService,
  ) {
  }

  onSubmit() {
    const routeParams = this.route.snapshot.queryParams;


    const loginResult = this.auth.logIn({
      username: this.model.username,
      password: this.model.password,
      token: routeParams.token || null
    });
    this.isLoading = true;
    loginResult.subscribe(
      () => {
        const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        if (returnUrl !== null) {
          this.router.navigate([returnUrl]);
        } else {
          this.router.navigate(['/']);
        }
      },
      error => {
        this.isLoading = false;
        this.notify.error(error['error'].message);
        console.error(error);
      });
  }

}
