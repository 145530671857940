import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material';
import { ThemeModule } from '@app/@theme/theme.module';
import { ForgotPasswordFinishFormComponent } from '@app/pages/login/forms/forgot-password-finish-form/forgot-password-finish-form.component';
import { ForgotPasswordFormComponent } from '@app/pages/login/forms/forgot-password-form/forgot-password-form.component';
import { RegistrationFormComponent } from '@app/pages/login/forms/registration-form/registration-form.component';
import { LoginRoutingModule, routedComponents } from '@app/pages/login/login-routing.module';
import { ForgotPasswordService } from '@app/pages/login/services/forgot-password.service';
import { RegistrationService } from '@app/pages/login/services/registration.service';
import { SharedModule } from '@app/shared/shared.module';
import { NbCheckboxModule } from '@nebular/theme';
import { LoginFormComponent } from './forms/login-form/login-form.component';

const FORMS = [
  LoginFormComponent,
  RegistrationFormComponent,
  ForgotPasswordFormComponent,
  ForgotPasswordFinishFormComponent,
];

@NgModule({
  declarations: [
    ...routedComponents,
    ...FORMS,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatTooltipModule,
    ThemeModule,
    LoginRoutingModule,
    NbCheckboxModule
  ],
  exports: [],
  providers: [
    ForgotPasswordService,
    RegistrationService,
  ]
})
export class LoginModule {
}
