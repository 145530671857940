import { environment } from '@/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaResolverService {

  readonly recaptchaKey: string;
  private readonly recaptchaEnabled: boolean;

  constructor() {
    this.recaptchaKey = environment.recaptchaKey;

    // Do not require Recaptcha validation on localhost for dev purposes, unless key is listed in env variables
    this.recaptchaEnabled = (environment.production || environment.recaptchaKey.length > 0);
  }

}
