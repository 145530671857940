import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/@theme/material/material.module';
import { ThemeModule } from '@app/@theme/theme.module';
import { ButtonsDetailPageComponent } from '@app/shared/components/buttons-detail-page/buttons-detail-page.component';
import { ButtonsEditPageComponent } from '@app/shared/components/buttons-edit-page/buttons-edit-page.component';
import { ButtonsListPageComponent } from '@app/shared/components/buttons-list-page/buttons-list-page.component';
import { ButtonsNewPageComponent } from '@app/shared/components/buttons-new-page/buttons-new-page.component';
import { ChangeLanguageComponent } from '@app/shared/components/change-language/change-language.component';
import { EntityDetailTableComponent } from '@app/shared/components/entity-detail-table/entity-detail-table.component';
import { FeaturesLevelComponent } from '@app/shared/components/features-level/features-level.component';
import {
  NbActionsModule,
  NbCardModule,
  NbDialogModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbSidebarService,
  NbTabsetModule,
  NbTooltipModule
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { RecaptchaModule } from 'ng-recaptcha';
import { LoaderComponent } from './components/loader/loader.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { RecaptchaResolverService } from './components/recaptcha/recaptchaResolver.service';
import { RemoveConfirmDialogComponent } from './dialogs/remove-confirm-dialog/remove-confirm-dialog.component';
import { AdminOnlyDirective } from './directives/admin-only.directive';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const NB_MODULES = [
  NbLayoutModule,
  NbSidebarModule,
  NbCardModule,
  NbMenuModule,
  NbActionsModule,
  NbTabsetModule,
  NbTooltipModule,
];

const COMPONENTS = [
  ChangeLanguageComponent,
  EntityDetailTableComponent,
  LoaderComponent,
  NotFoundComponent,
  RecaptchaComponent,
  ButtonsEditPageComponent,
  ButtonsListPageComponent,
  ButtonsDetailPageComponent,
  ButtonsNewPageComponent,
  FeaturesLevelComponent,
];

const DIALOGS = [
  RemoveConfirmDialogComponent,
];

@NgModule({
  declarations: [
    AdminOnlyDirective,
    ...COMPONENTS,
    ...DIALOGS,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule,
    RecaptchaModule,
    ThemeModule,
    ...NB_MODULES,
    NbDialogModule.forChild(),
  ],
  exports: [
    MaterialModule,
    RouterModule,
    TranslateModule,
    ThemeModule,
    ...COMPONENTS,
    ...NB_MODULES,
    AdminOnlyDirective,
  ],
  entryComponents: [
    ...DIALOGS,
  ],
  providers: [
    RecaptchaResolverService,
    NbSidebarService,
    DecimalPipe
  ]
})
export class SharedModule {
}
