<nb-card (keydown.enter)="onSubmit()" class="forgot-password-form">
  <nb-card-header>
    <h2>{{'page.forgotPassword.title' | translate}}</h2>
  </nb-card-header>
  <nb-card-body>
    <div class="edit-page__group">
      <form (ngSubmit)="validate() && onSubmit()">
        <input [(ngModel)]="model.username" fullWidth name="username"
               nbInput
               placeholder="{{'users.attribute.email' | translate}} / {{'users.attribute.username' | translate}}"
               required type="username">

        <app-recaptcha #captchaRef (resolved)="model.captcha = $event"></app-recaptcha>

        <div class="text-center">
          <button [disabled]="submitted" class="login-form__button" fullWidth hero nbButton status="primary"
                  type="submit">
            {{'button.recoverPassword' | translate}}
          </button>
        </div>
      </form>
    </div>
  </nb-card-body>
</nb-card>
