import { Component, OnInit } from '@angular/core';
import { FeatureLevelService } from '@app/@core/services/feature-level/feature-level.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-features-level',
  templateUrl: './features-level.component.html',
  styleUrls: ['./features-level.component.scss']
})
export class FeaturesLevelComponent implements OnInit {

  currentLevel: number;

  constructor(
    public translate: TranslateService,
    public service: FeatureLevelService,
  ) {
  }

  ngOnInit(): void {
    this.currentLevel = this.service.currentLevel;
  }

  onChange(value: string) {
    this.service.currentLevel = +value;
    window.location.reload();
  }

}
