/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./recaptcha.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-recaptcha/recaptcha/recaptcha.component.ngfactory";
import * as i3 from "ng-recaptcha/recaptcha/recaptcha.component";
import * as i4 from "ng-recaptcha/recaptcha/recaptcha-loader.service";
import * as i5 from "ng-recaptcha/recaptcha/recaptcha-settings";
import * as i6 from "@angular/common";
import * as i7 from "./recaptcha.component";
import * as i8 from "./recaptchaResolver.service";
var styles_RecaptchaComponent = [i0.styles];
var RenderType_RecaptchaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecaptchaComponent, data: {} });
export { RenderType_RecaptchaComponent as RenderType_RecaptchaComponent };
function View_RecaptchaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "recaptcha-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "re-captcha", [["badge", "inline"], ["size", "invisible"]], [[1, "id", 0]], [[null, "resolved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resolved" === en)) {
        var pd_0 = (_co.captchaResolved($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RecaptchaComponent_0, i2.RenderType_RecaptchaComponent)), i1.ɵdid(2, 4374528, [[1, 4], ["captchaRef", 4]], 0, i3.RecaptchaComponent, [i1.ElementRef, i4.RecaptchaLoaderService, i1.NgZone, [2, i5.RECAPTCHA_SETTINGS]], { siteKey: [0, "siteKey"], size: [1, "size"], badge: [2, "badge"] }, { resolved: "resolved" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.captcha.recaptchaKey; var currVal_2 = "invisible"; var currVal_3 = "inline"; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); }); }
export function View_RecaptchaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ref: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RecaptchaComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.captcha.recaptchaKey; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RecaptchaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recaptcha", [], null, null, null, View_RecaptchaComponent_0, RenderType_RecaptchaComponent)), i1.ɵdid(1, 49152, null, 0, i7.RecaptchaComponent, [i8.RecaptchaResolverService], null, null)], null, null); }
var RecaptchaComponentNgFactory = i1.ɵccf("app-recaptcha", i7.RecaptchaComponent, View_RecaptchaComponent_Host_0, {}, { resolved: "resolved", ref: "ref" }, []);
export { RecaptchaComponentNgFactory as RecaptchaComponentNgFactory };
