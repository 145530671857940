import { IdentifiedEntityInterface } from '@app/@core/schema/identified-entity.interface';
import { ApiInterface } from '@app/@core/services/api/api.interface';
import { AbstractCrudService } from '@app/@core/services/crud/abstract-crud.service';
import { CrudSingleInterface } from '@app/@core/services/crud/crud-single.interface';
import { Observable } from 'rxjs';

export abstract class CrudSingleService extends AbstractCrudService implements CrudSingleInterface {

  protected constructor(
    protected api: ApiInterface,
  ) {
    super(api);
  }

  get(): Observable<any> {
    return this.callApiIfSupported('get', this.api.get(this._urlNameSpace));
  }

  update(entity: IdentifiedEntityInterface): Observable<any> {
    return this.callApiIfSupported('update', this.api.put(this._urlNameSpace, entity));
  }

  remove(): Observable<any> {
    return this.callApiIfSupported('remove', this.api.delete(this._urlNameSpace, {}));
  }

}
