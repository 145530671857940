import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ForgotPasswordModel } from '@app/pages/login/model/forgot-password.model';
import { ForgotPasswordService } from '@app/pages/login/services/forgot-password.service';
import { RecaptchaComponent } from '@app/shared/components/recaptcha/recaptcha.component';
import { RecaptchaResolverService } from '@app/shared/components/recaptcha/recaptchaResolver.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent {

  submitted = false;
  model = new ForgotPasswordModel();
  @ViewChild('captchaRef') captchaRef: RecaptchaComponent;

  constructor(
    private notify: NotificationsService,
    private captcha: RecaptchaResolverService,
    private router: Router,
    private service: ForgotPasswordService,
    private translate: TranslateService
  ) {
  }

  validate(): boolean {
    if (this.submitted) {
      this.notify.error(this.translate.instant('notif.formAlreadySubmitted'),
        this.translate.instant('notif.formAlreadySubmittedText'));
      return false;
    }
    return true;
  }

  onSubmit(): void {
    this.submitted = true;

    this.captchaRef.resolved.subscribe(() => {
      this.captchaRef.resolved.unsubscribe();
      this.service.submit(this.model).subscribe(
        () => {
          this.notify.success(
            this.translate.instant('page.forgotPassword.successs'),
            this.translate.instant('notif.checkMailInstructions')
          );
          this.router.navigate(['/']);
        },
        error => {
          this.submitted = false;
          this.notify.error(error['error'].message);
          console.error(error);
        });
    });

    this.captchaRef.execute();
  }

}
