<nb-card class="login-form">
  <nb-card-header>
    <h2>{{'page.registration.title' | translate}}</h2>
  </nb-card-header>
  <nb-card-body>
    <div class="edit-page__group">
      <p>
        {{'page.registration.useWhenCompanyNotReg' | translate}}
      </p>
      <form #f="ngForm" (ngSubmit)="validate(f) && onSubmit(f)">
        <input fullWidth minlenght="5" name="email" nbInput
               ngModel
               placeholder="{{'users.attribute.email' | translate}} *"
               required
               type="email">

        <input fullWidth name="phone" nbInput ngModel
               placeholder="{{'users.attribute.phone' | translate}} *"
               required
               type="tel">

        <input fullWidth name="fullName" nbInput ngModel
               placeholder="{{'users.attribute.fullName' | translate}} *"
               required
               type="text">

        <input fullWidth name="password" nbInput ngModel
               placeholder="{{'users.attribute.password' | translate}} *"
               required type="password">

        <input fullWidth name="passwordAgain" nbInput ngModel
               placeholder="{{'users.attribute.passwordAgain' | translate}} *"
               required type="password">

        <hr/>

        <h3>{{'page.company.title' | translate}}</h3>

        <nb-checkbox [value]="alreadyInPOS" (change)="alreadyInPOS = $event.target.checked">
          {{'page.registration.alreadyInPOS' | translate}}
        </nb-checkbox>

        <input *ngIf="!alreadyInPOS" fullWidth maxlength="255" name="companyName" nbInput
               ngModel
               placeholder="{{'page.company.name' | translate}} *" required
               type="text">

        <input fullWidth maxlength="8" name="companyId" nbInput
               ngModel
               placeholder="{{'page.company.id' | translate}} *" required
               type="text">

        <input *ngIf="!alreadyInPOS" fullWidth maxlength="12" name="companyVatId" nbInput
               ngModel
               placeholder="{{'page.company.vatId' | translate}} *" required
               type="text">

        <input *ngIf="!alreadyInPOS" fullWidth maxlength="255" name="companyStreet" nbInput
               ngModel
               placeholder="{{'page.company.street' | translate}} *" required
               type="text">

        <input *ngIf="!alreadyInPOS" fullWidth maxlength="255" name="companyCity" nbInput
               ngModel
               placeholder="{{'page.company.city' | translate}} *" required
               type="text">

        <input *ngIf="!alreadyInPOS" fullWidth maxlength="10" name="companyPostalCode" nbInput
               ngModel
               placeholder="{{'page.company.postalCode' | translate}} *" required
               type="text">

        <nb-select *ngIf="!alreadyInPOS" fullWidth name="companyCountryAbbrev" ngModel
                   outline placeholder="{{'page.company.country' | translate}} *"
                   required>
          <nb-option value="CZ">Česká republika</nb-option>
        </nb-select>

        <app-recaptcha #captchaRef></app-recaptcha>

        <div class="text-center">
          <p *ngIf="f.invalid">
            <i>{{'forms.fillInRequired' | translate}} (*)</i>
          </p>
          <button [disabled]="f.invalid || submitted" class="login-form__button" hero nbButton status="primary"
                  type="submit">
            {{'button.register' | translate}}
          </button>
        </div>
      </form>
    </div>
  </nb-card-body>
</nb-card>
