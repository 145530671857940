import { AuthService } from '@/app/@core/services/auth/auth.service';
import { Component, Input, OnInit } from '@angular/core';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { Company } from '@app/pages/company/model/company.model';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { LayoutService } from '../../../@core/utils';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  company: Company;
  branches: any[] = [];
  companyOption: any = null;
  currentBranch = {
    id: -1,
  };
  isLoading = false;
  userResponse: any;

  constructor(
    private userMeService: UserMeService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private layoutService: LayoutService,
    public translate: TranslateService,
    public authService: AuthService,
  ) {
    const cachedId = this.authService.getBranchId();
    if (cachedId === '') {
      this.currentBranch.id = -1;
    } else {
      this.currentBranch.id = Number.parseInt(cachedId);
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    const user$ = this.userMeService.getUserInfo(true);
    const branches$ = this.userMeService.getBranches();
    forkJoin(user$, branches$).subscribe(
      ([userRes, branchesRes]) => {
        this.userResponse = userRes;
        this.company = userRes.company;
        if (this.isAdmin()) {
          this.companyOption = {id: -1, name: this.company.name};
        }
        this.branches.push(...branchesRes.branch);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        throw new Error(error);
      });
  }

  isAdmin() {
    return this.authService.isAdmin();
  }

  onBranchChange(e) {
    if (e === -1) {
      this.authService.setBranchId('');
    } else {
      this.authService.setBranchId(this.currentBranch.id.toString());
    }
    window.location.reload();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

}
