import * as i0 from "@angular/core";
var LocalStorageService = /** @class */ (function () {
    function LocalStorageService() {
    }
    LocalStorageService.prototype.get = function (key) {
        return window.localStorage.getItem(key);
    };
    LocalStorageService.prototype.store = function (key, value) {
        window.localStorage.setItem(key, value);
    };
    LocalStorageService.prototype.hasKey = function (key) {
        return window.localStorage.getItem(key) !== null;
    };
    LocalStorageService.prototype.remove = function (key) {
        if (this.hasKey(key)) {
            window.localStorage.removeItem(key);
        }
    };
    LocalStorageService.ngInjectableDef = i0.defineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(); }, token: LocalStorageService, providedIn: "root" });
    return LocalStorageService;
}());
export { LocalStorageService };
