<nb-card>
  <nb-card-header>
    {{'dialog.removeConfirm.title' | translate}}
  </nb-card-header>
  <nb-card-body>
    {{'dialog.removeConfirm.question' | translate}}
  </nb-card-body>
  <nb-card-footer class="action-buttons-dialog">
    <button (click)="onDismiss()" nbButton outline status="info">
      <i class="fa fa-window-close"></i>&nbsp;
      {{'button.no' | translate}}
    </button>
    <button (click)="onConfirm()" hero nbButton status="danger">
      <i class="fa fa-trash"></i>&nbsp;
      {{'button.yes' | translate}}
    </button>
  </nb-card-footer>
</nb-card>
