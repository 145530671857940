import { Component, Input } from '@angular/core';
import { AbstractEntitySchemaInterface } from '@app/@core/schema/abstract-entity.schema.interface';
import { IdentifiedEntityInterface } from '@app/@core/schema/identified-entity.interface';
import { EntityNavigatorService } from '@app/@core/services/entity-navigator/entity-navigator.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-buttons-edit-page',
  templateUrl: './buttons-edit-page.component.html',
  styleUrls: ['./buttons-edit-page.component.scss']
})
export class ButtonsEditPageComponent {

  @Input() schema: AbstractEntitySchemaInterface;
  @Input() entity: IdentifiedEntityInterface;

  constructor(
    public translate: TranslateService,
    private navigator: EntityNavigatorService,
  ) {
  }

  onBackToList(): void {
    if (this.schema.allowList) {
      this.navigator.list(this.schema);
    }
  }

  onDetail(): void {
    if (this.schema.allowDetail) {
      this.navigator.detail(this.schema, this.entity);
    }
  }

}
