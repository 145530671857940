<div class="registration-page">
  <div class="registration-page__form">
    <div class="action-buttons">
      <button hero nbButton routerLink="/login" status="success">
        <i class="fa fa-arrow-left"></i>&nbsp;
        {{'button.backToLogin' | translate}}
      </button>
    </div>
    <app-registration-form></app-registration-form>
  </div>
</div>
