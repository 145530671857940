import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/@core/guards/auth.guard';
import { BlankLayoutComponent, OneColumnLayoutComponent } from '@app/@theme/layouts';
import { NotFoundComponent } from '@app/shared/pages/not-found/not-found.component';

const routes: Routes = [
  {
    path: 'login',
    component: BlankLayoutComponent,
    loadChildren: './pages/login/login.module#LoginModule',
  },
  {
    path: '',
    component: OneColumnLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: './pages/pages.module#PagesModule',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
