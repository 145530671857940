import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/@core/services/auth/auth.service';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router,
    private userMeService: UserMeService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const authenticated = this.auth.isAuthenticated();

      if (!authenticated) {
        this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      }

      return this.userMeService.getUserInfo(true)
        .subscribe(companyUser => {
          if (companyUser.companyUser.role.name === 'Inventura') {
            if (state.url.includes('stock-taking')) {
              resolve(authenticated);
            } else {
              this.router.navigate(['/stock-taking']);
            }
          } else {
            resolve(authenticated);
          }
        });
    });

  }
}
