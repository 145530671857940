var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { ApiService } from '@app/@core/services/api/api.service';
import { CrudSingleService } from '@app/@core/services/crud/crud-single.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../api/api.service";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
var UserMeService = /** @class */ (function (_super) {
    __extends(UserMeService, _super);
    function UserMeService(api, translate, router) {
        var _this = _super.call(this, api) || this;
        _this.api = api;
        _this.translate = translate;
        _this.router = router;
        _this._urlNameSpace = '/users/me';
        _this.supportedMethods = ['get', 'update'];
        return _this;
    }
    UserMeService.prototype.clearCache = function () {
        this.user = undefined;
    };
    UserMeService.prototype.setLang = function (lang) {
        var _this = this;
        return new Observable(function (observer) {
            _this.getUserInfo(true).subscribe(function (companyUser) {
                var user = companyUser.user;
                user.lang = lang;
                _this.api.put(_this.urlNameSpace, user).subscribe(function (data) {
                    observer.next(data['data']);
                });
            });
        });
    };
    UserMeService.prototype.getUserInfo = function (fromCache) {
        var _this = this;
        if (fromCache === void 0) { fromCache = false; }
        if (typeof this.user === 'undefined' || fromCache === false) {
            return this.fetchUserInfo();
        }
        return new Observable(function (observer) {
            observer.next(_this.user);
            observer.complete();
        });
    };
    UserMeService.prototype.getBranches = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this.api.get('/branches/users/me').subscribe(function (data) {
                observer.next(data['data'][0]);
                observer.complete();
            });
        });
    };
    UserMeService.prototype.fetchUserInfo = function () {
        var _this = this;
        return new Observable(function (observer) {
            var user$ = _this.api.get(_this.urlNameSpace);
            user$.subscribe(function (data) {
                var result;
                if (data['data']) {
                    _this.user = result = data['data'][0];
                    _this._companyUserRole = result.companyUser.role.name || null;
                }
                if (result['lang']) {
                    _this.translate.use(result['lang']);
                }
                observer.next(result);
                observer.complete();
            }, function (error) {
                console.log({ error: error, user: user$ });
                if (error.code === 401 || error.code === 403) {
                    _this.router.navigate(['/login']);
                }
                observer.error(error);
            });
        });
    };
    Object.defineProperty(UserMeService.prototype, "companyUserRole", {
        get: function () {
            return this._companyUserRole;
        },
        enumerable: true,
        configurable: true
    });
    UserMeService.ngInjectableDef = i0.defineInjectable({ factory: function UserMeService_Factory() { return new UserMeService(i0.inject(i1.ApiService), i0.inject(i2.TranslateService), i0.inject(i3.Router)); }, token: UserMeService, providedIn: "root" });
    return UserMeService;
}(CrudSingleService));
export { UserMeService };
