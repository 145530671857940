import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IdentifiedEntityCollectionSchemaInterface } from '@app/@core/schema/identified-entity-collection.schema.interface';
import { IdentifiedEntityInterface } from '@app/@core/schema/identified-entity.interface';
import { EntityNavigatorService } from '@app/@core/services/entity-navigator/entity-navigator.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-buttons-detail-page',
  templateUrl: './buttons-detail-page.component.html',
  styleUrls: ['./buttons-detail-page.component.scss']
})
export class ButtonsDetailPageComponent {

  @Input() schema: IdentifiedEntityCollectionSchemaInterface;
  @Input() entity: IdentifiedEntityInterface;
  @Input() isLoading = false;
  @Input() showCrudActions = false;
  @Output() refresh = new EventEmitter<string>();

  constructor(
    public translate: TranslateService,
    private navigator: EntityNavigatorService,
  ) {
  }

  onPrint(): void {
    window.print();
  }

  onEdit(): void {
    if (this.schema.allowEdit) {
      this.navigator.edit(this.schema, this.entity);
    }
  }

  onRemove(): void {
    if (this.schema.allowRemove) {
      this.navigator.remove(this.schema, this.entity).subscribe(() => {
      }, () => {
      }, () => {
        this.isLoading = false;
      });
    }
  }

  onBackToList(): void {
    if (this.schema.allowList) {
      this.navigator.list(this.schema);
    }
  }

}
