import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@app/pages/login/login.component';
import { ForgotPasswordFinishPageComponent } from '@app/pages/login/pages/forgot-password-finish-page/forgot-password-finish-page.component';
import { ForgotPasswordPageComponent } from '@app/pages/login/pages/forgot-password-page/forgot-password-page.component';
import { LoginPageComponent } from '@app/pages/login/pages/login-page/login-page.component';
import { RegistrationPageComponent } from '@app/pages/login/pages/registration-page/registration-page.component';

export const routedComponents = [
  LoginComponent,
  LoginPageComponent,
  ForgotPasswordPageComponent,
  ForgotPasswordFinishPageComponent,
  RegistrationPageComponent,
];

const routes: Routes = [{
  path: '',
  component: LoginComponent,
  children: [
    {
      path: '',
      component: LoginPageComponent,
    },
    {
      path: 'forgot-password',
      component: ForgotPasswordPageComponent,
    },
    {
      path: 'forgot-password/finish/:token',
      component: ForgotPasswordFinishPageComponent,
    },
    {
      path: 'registration',
      component: RegistrationPageComponent,
    },
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {
}
