import { ApiService } from '@app/@core/services/api/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../@core/services/api/api.service";
var ForgotPasswordService = /** @class */ (function () {
    function ForgotPasswordService(api) {
        this.api = api;
    }
    ForgotPasswordService.prototype.submit = function (model) {
        return this.api.post('/users/forgotten-password', {
            username: model.username,
            captcha: model.captcha
        });
    };
    ForgotPasswordService.prototype.finish = function (model) {
        return this.api.post('/users/forgotten-password/finish', {
            token: model.token,
            newPassword: model.newPassword,
            captcha: model.captcha
        });
    };
    ForgotPasswordService.ngInjectableDef = i0.defineInjectable({ factory: function ForgotPasswordService_Factory() { return new ForgotPasswordService(i0.inject(i1.ApiService)); }, token: ForgotPasswordService, providedIn: "root" });
    return ForgotPasswordService;
}());
export { ForgotPasswordService };
