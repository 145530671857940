import { Component } from '@angular/core';

@Component({
  selector: 'app-login',
  template: `
    <router-outlet></router-outlet>
  `
})
export class LoginComponent {
}
