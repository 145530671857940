import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/@core/services/auth/auth.service';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss']
})
export class ChangeLanguageComponent implements OnInit {

  currentLang: string;

  constructor(
    private translate: TranslateService,
    private userMeService: UserMeService,
    private auth: AuthService
  ) {
  }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang ? this.translate.currentLang : this.translate.defaultLang;
  }

  onChangeLang(value: string) {
    if (this.auth.isAuthenticated()) {
      this.userMeService.setLang(value).subscribe(() => {
        this.translate.use(value);
      });
    } else {
      this.translate.use(value);
    }
  }

}
