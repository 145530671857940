<nb-layout>
  <nb-layout-header [fixed]="true" class="d-print-none">
    <app-header></app-header>
  </nb-layout-header>

  <nb-sidebar class="d-print-none">
    <app-side-nav></app-side-nav>
  </nb-sidebar>

  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>
