// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  staging: true,
  apiEndpoint: 'https://sklad-api-dev.cink.cloud',
  recaptchaKey: '6Lc1XHEUAAAAAE3u4RYINZUj1ROB4xVupKxQDuYB',
  hmr: false,
};
