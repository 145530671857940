import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/@core/services/auth/auth.service';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { NbMenuService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
var UserMenuComponent = /** @class */ (function () {
    function UserMenuComponent(auth, router, userMeService, notify, translate, menuService) {
        this.auth = auth;
        this.router = router;
        this.userMeService = userMeService;
        this.notify = notify;
        this.translate = translate;
        this.menuService = menuService;
        this.menuItems = [];
        this.isLoading = false;
    }
    UserMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        var user$ = this.userMeService.getUserInfo(true);
        user$.subscribe(function (data) {
            _this.userResponse = data;
            _this.user = data.user;
            _this.company = data.company;
            _this.loadMenuItems();
            _this.menuService.onItemClick()
                .subscribe(function (event) {
                if (event.tag === 'userMenu' && event.item.target === 'sign-out') {
                    _this.onLogOut();
                }
            });
            _this.isLoading = false;
        });
    };
    UserMenuComponent.prototype.onLogOut = function () {
        var _this = this;
        var logoutResult = this.auth.logOut();
        logoutResult.subscribe(function () {
            _this.router.navigate(['/login']);
        }, function (error) {
            _this.notify.error(error['error'].message);
            console.error(error);
        });
    };
    UserMenuComponent.prototype.loadMenuItems = function () {
        var menuItems = this.getMenuItems();
        this.menuItems = [];
        var _loop_1 = function (each) {
            this_1.menuItems.push(each);
            this_1.translate.stream(each.title).subscribe(function (res) {
                each.title = res;
            });
            if (each.hasOwnProperty('children')) {
                var _loop_2 = function (eachChild) {
                    this_1.translate.stream(eachChild.title).subscribe(function (res) {
                        eachChild.title = res;
                    });
                };
                for (var _i = 0, _a = each.children; _i < _a.length; _i++) {
                    var eachChild = _a[_i];
                    _loop_2(eachChild);
                }
            }
        };
        var this_1 = this;
        for (var _i = 0, menuItems_1 = menuItems; _i < menuItems_1.length; _i++) {
            var each = menuItems_1[_i];
            _loop_1(each);
        }
    };
    UserMenuComponent.prototype.getMenuItems = function () {
        return [
            {
                title: 'page.profile.titleShort',
                link: '/profile',
                icon: 'fa fa-user',
            },
            {
                title: 'page.company.my',
                link: "/company/" + this.company.id + "/",
                icon: 'fa fa-city'
            },
            {
                title: 'button.log_out',
                target: 'sign-out',
                icon: 'fa fa-sign-out-alt',
            },
        ];
    };
    return UserMenuComponent;
}());
export { UserMenuComponent };
