import { environment } from '@/environments/environment';
import { LocalStorageService } from '@app/@core/services/storage/local-storage.service';
import { FEATURES_LEVEL_KEY } from '@app/@core/services/storage/storage-keys';
import * as i0 from "@angular/core";
import * as i1 from "../storage/local-storage.service";
var FeatureLevelService = /** @class */ (function () {
    function FeatureLevelService(storage) {
        this.storage = storage;
        this.LEVEL_NONE = 0; // Feature is hidden for everyone
        this.LEVEL_DEV = 10; // Feature is visible only for developers
        this.LEVEL_BETA = 20; // Feature is visible for beta testers
        this.LEVEL_PROD = 30; // Feature is visible for all
        var levelInStorage = this.currentLevelInStorage;
        this._currentLevel = (levelInStorage !== null) ? levelInStorage : this.defaultLevel;
    }
    Object.defineProperty(FeatureLevelService.prototype, "currentLevel", {
        get: function () {
            return this._currentLevel;
        },
        set: function (value) {
            if ([this.LEVEL_DEV, this.LEVEL_BETA, this.LEVEL_PROD].includes(value)) {
                this.storage.store(FEATURES_LEVEL_KEY, value.toString());
                this._currentLevel = value;
            }
            else {
                console.error('Invalid value!');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FeatureLevelService.prototype, "defaultLevel", {
        get: function () {
            if (environment.hasOwnProperty('staging') && environment['staging'] === true) {
                return this.LEVEL_BETA;
            }
            else if (environment.hasOwnProperty('production') && environment['production'] === false) {
                return this.LEVEL_DEV;
            }
            return this.LEVEL_PROD;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FeatureLevelService.prototype, "currentLevelInStorage", {
        get: function () {
            var storageValue = this.storage.get(FEATURES_LEVEL_KEY);
            return (storageValue !== null) ? +storageValue : null;
        },
        enumerable: true,
        configurable: true
    });
    FeatureLevelService.prototype.isAvailable = function (requiredMinimumLevel) {
        return requiredMinimumLevel >= this._currentLevel;
    };
    FeatureLevelService.ngInjectableDef = i0.defineInjectable({ factory: function FeatureLevelService_Factory() { return new FeatureLevelService(i0.inject(i1.LocalStorageService)); }, token: FeatureLevelService, providedIn: "root" });
    return FeatureLevelService;
}());
export { FeatureLevelService };
