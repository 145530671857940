import { Component, OnInit } from '@angular/core';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './@core/services/auth/auth.service';
import { AnalyticsService } from './@core/utils/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  defaultLang = 'en';
  notificationOptions = {
    timeOut: 5000,
    showProgressBar: false,
    preventDuplicates: true,
    maxStack: 6,
  };

  constructor(
    authService: AuthService,
    private translate: TranslateService,
    private userMeService: UserMeService,
    private analytics: AnalyticsService
  ) {
    translate.setDefaultLang(this.defaultLang);

    if (authService.isAuthenticated()) {
      userMeService.getUserInfo(false).subscribe((companyUser) => {
        if (companyUser.user.lang !== null) {
          this.translate.use(companyUser.user.lang);
        } else {
          this.userMeService.setLang(this.translate.defaultLang).subscribe();
        }
      });
    } else {
      translate.use(translate.getBrowserLang());
    }
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
  }

}
