import { Observable } from 'rxjs';
var AbstractCrudService = /** @class */ (function () {
    function AbstractCrudService(api) {
        this.api = api;
    }
    Object.defineProperty(AbstractCrudService.prototype, "urlNameSpace", {
        get: function () {
            return this._urlNameSpace;
        },
        enumerable: true,
        configurable: true
    });
    AbstractCrudService.prototype.callApiIfSupported = function (method, successCallback) {
        if (this.checkMethodIsSupported(method)) {
            return successCallback;
        }
        else {
            return this.handleUnsupportedRequest();
        }
    };
    AbstractCrudService.prototype.checkMethodIsSupported = function (methodName) {
        return (typeof this.supportedMethods === 'undefined' || !this.supportedMethods || this.supportedMethods.includes(methodName));
    };
    // Call this method when requst is not supported (e.g. in override of abstract method)
    AbstractCrudService.prototype.handleUnsupportedRequest = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this.handleUnsupportedError();
            observer.error();
            observer.complete();
        });
    };
    AbstractCrudService.prototype.handleUnsupportedError = function () {
        console.error('This request is not supported!');
    };
    return AbstractCrudService;
}());
export { AbstractCrudService };
