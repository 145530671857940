import { ApiService } from '@app/@core/services/api/api.service';
import { ADMIN_STORAGE_KEY, BRANCH_ID_STORAGE_KEY, FEATURES_LEVEL_KEY, TOKEN_STORAGE_KEY } from '@app/@core/services/storage/storage-keys';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../storage/local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "../api/api.service";
import * as i2 from "../storage/local-storage.service";
import * as i3 from "../user-me/user-me.service";
import * as i4 from "@ngx-translate/core";
var AuthService = /** @class */ (function () {
    function AuthService(api, storage, userMeService, translate) {
        this.api = api;
        this.storage = storage;
        this.userMeService = userMeService;
        this.translate = translate;
    }
    AuthService.prototype.isAuthenticated = function () {
        return this.storage.hasKey(TOKEN_STORAGE_KEY);
    };
    AuthService.prototype.isAdmin = function () {
        return this.storage.get(ADMIN_STORAGE_KEY) === 'true';
    };
    AuthService.prototype.getToken = function () {
        var token = this.storage.get(TOKEN_STORAGE_KEY);
        return token !== null ? token : '';
    };
    AuthService.prototype.getBranchId = function () {
        var branchId = this.storage.get(BRANCH_ID_STORAGE_KEY);
        return branchId !== null ? branchId : '';
    };
    AuthService.prototype.setBranchId = function (id) {
        this.storage.store(BRANCH_ID_STORAGE_KEY, id);
    };
    AuthService.prototype.logInToken = function (params) {
        var _this = this;
        return new Observable(function (observer) {
            var data = _this.api.post('/login-token', params);
            data.subscribe(function (result) {
                _this.storage.store(TOKEN_STORAGE_KEY, result['data'].token);
                _this.storage.store(ADMIN_STORAGE_KEY, result['data'].isAdmin);
                if (!_this.getBranchId()) {
                    _this.setBranchId(result['data'].branches[0]);
                }
                _this.userMeService.getUserInfo(false).subscribe(function (companyUser) {
                    if (companyUser.user.lang !== null) {
                        _this.translate.use(companyUser.user.lang);
                    }
                    else {
                        _this.userMeService.setLang(_this.translate.defaultLang).subscribe();
                    }
                });
                observer.next(result);
            }, function (error) {
                observer.error(error);
            });
        });
    };
    AuthService.prototype.logIn = function (params) {
        var _this = this;
        return new Observable(function (observer) {
            var data = _this.api.post('/login', params);
            data.subscribe(function (result) {
                _this.storage.store(TOKEN_STORAGE_KEY, result['data'].token);
                _this.storage.store(ADMIN_STORAGE_KEY, result['data'].isAdmin);
                if (!_this.getBranchId()) {
                    _this.setBranchId(result['data'].branches[0]);
                }
                _this.userMeService.getUserInfo(false).subscribe(function (companyUser) {
                    if (companyUser.user.lang !== null) {
                        _this.translate.use(companyUser.user.lang);
                    }
                    else {
                        _this.userMeService.setLang(_this.translate.defaultLang).subscribe();
                    }
                });
                observer.next(result);
            }, function (error) {
                observer.error(error);
            });
        });
    };
    AuthService.prototype.logOut = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this.userMeService.clearCache();
            var data = _this.api.post('/logout', {});
            data.subscribe(function (result) {
                _this.storage.remove(TOKEN_STORAGE_KEY);
                _this.storage.remove(ADMIN_STORAGE_KEY);
                _this.storage.remove(FEATURES_LEVEL_KEY);
                observer.next(result);
            }, function (error) {
                observer.error(error);
            });
        });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.ApiService), i0.inject(i2.LocalStorageService), i0.inject(i3.UserMeService), i0.inject(i4.TranslateService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
