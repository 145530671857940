import { AuthService } from '@/app/@core/services/auth/auth.service';
import { OnInit } from '@angular/core';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { LayoutService } from '../../../@core/utils';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(userMeService, sidebarService, menuService, layoutService, translate, authService) {
        this.userMeService = userMeService;
        this.sidebarService = sidebarService;
        this.menuService = menuService;
        this.layoutService = layoutService;
        this.translate = translate;
        this.authService = authService;
        this.position = 'normal';
        this.branches = [];
        this.companyOption = null;
        this.currentBranch = {
            id: -1,
        };
        this.isLoading = false;
        var cachedId = this.authService.getBranchId();
        if (cachedId === '') {
            this.currentBranch.id = -1;
        }
        else {
            this.currentBranch.id = Number.parseInt(cachedId);
        }
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        var user$ = this.userMeService.getUserInfo(true);
        var branches$ = this.userMeService.getBranches();
        forkJoin(user$, branches$).subscribe(function (_a) {
            var userRes = _a[0], branchesRes = _a[1];
            var _b;
            _this.userResponse = userRes;
            _this.company = userRes.company;
            if (_this.isAdmin()) {
                _this.companyOption = { id: -1, name: _this.company.name };
            }
            (_b = _this.branches).push.apply(_b, branchesRes.branch);
            _this.isLoading = false;
        }, function (error) {
            _this.isLoading = false;
            throw new Error(error);
        });
    };
    HeaderComponent.prototype.isAdmin = function () {
        return this.authService.isAdmin();
    };
    HeaderComponent.prototype.onBranchChange = function (e) {
        if (e === -1) {
            this.authService.setBranchId('');
        }
        else {
            this.authService.setBranchId(this.currentBranch.id.toString());
        }
        window.location.reload();
    };
    HeaderComponent.prototype.toggleSidebar = function () {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();
        return false;
    };
    HeaderComponent.prototype.goToHome = function () {
        this.menuService.navigateHome();
    };
    return HeaderComponent;
}());
export { HeaderComponent };
