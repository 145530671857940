import { Injectable } from '@angular/core';
import { ApiService } from '@app/@core/services/api/api.service';
import {
  ADMIN_STORAGE_KEY,
  BRANCH_ID_STORAGE_KEY,
  FEATURES_LEVEL_KEY,
  TOKEN_STORAGE_KEY
} from '@app/@core/services/storage/storage-keys';
import { UserMeService } from '@app/@core/services/user-me/user-me.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private api: ApiService,
    private storage: LocalStorageService,
    private userMeService: UserMeService,
    private translate: TranslateService
  ) {
  }

  isAuthenticated(): boolean {
    return this.storage.hasKey(TOKEN_STORAGE_KEY);
  }

  isAdmin(): boolean {
    return this.storage.get(ADMIN_STORAGE_KEY) === 'true';
  }

  getToken(): string {
    const token = this.storage.get(TOKEN_STORAGE_KEY);
    return token !== null ? token : '';
  }

  getBranchId(): string {
    const branchId = this.storage.get(BRANCH_ID_STORAGE_KEY);
    return branchId !== null ? branchId : '';
  }

  setBranchId(id: string) {
    this.storage.store(BRANCH_ID_STORAGE_KEY, id);
  }

  logInToken(params: { auth: string, branch: number, token: string }) {
    return new Observable(observer => {
      const data = this.api.post('/login-token', params);
      data.subscribe(
        result => {
          this.storage.store(TOKEN_STORAGE_KEY, result['data'].token);
          this.storage.store(ADMIN_STORAGE_KEY, result['data'].isAdmin);
          if (!this.getBranchId()) {
            this.setBranchId(result['data'].branches[0]);
          }
          this.userMeService.getUserInfo(false).subscribe((companyUser) => {
            if (companyUser.user.lang !== null) {
              this.translate.use(companyUser.user.lang);
            } else {
              this.userMeService.setLang(this.translate.defaultLang).subscribe();
            }
          });
          observer.next(result);
        },
        error => {
          observer.error(error);
        });
    });
  }

  logIn(params: { username: string, password: string, token?: string }) {
    return new Observable(observer => {
      const data = this.api.post('/login', params);
      data.subscribe(
        result => {
          this.storage.store(TOKEN_STORAGE_KEY, result['data'].token);
          this.storage.store(ADMIN_STORAGE_KEY, result['data'].isAdmin);
          if (!this.getBranchId()) {
            this.setBranchId(result['data'].branches[0]);
          }
          this.userMeService.getUserInfo(false).subscribe((companyUser) => {
            if (companyUser.user.lang !== null) {
              this.translate.use(companyUser.user.lang);
            } else {
              this.userMeService.setLang(this.translate.defaultLang).subscribe();
            }
          });
          observer.next(result);
        },
        error => {
          observer.error(error);
        });
    });
  }

  logOut() {
    return new Observable(observer => {
      this.userMeService.clearCache();
      const data = this.api.post('/logout', {});
      data.subscribe(
        result => {
          this.storage.remove(TOKEN_STORAGE_KEY);
          this.storage.remove(ADMIN_STORAGE_KEY);
          this.storage.remove(FEATURES_LEVEL_KEY);
          observer.next(result);
        },
        error => {
          observer.error(error);
        });
    });
  }

}
