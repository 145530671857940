import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/@core/services/auth/auth.service';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.checkToken();
  }

  private checkToken() {
    const params: any = this.route.snapshot.queryParams;

    if (params.auth || null) {
      const loginResult = this.authService.logInToken({
        auth: params.auth,
        branch: params.branch,
        token: params.token
      });

      this.isLoading = true;
      loginResult.subscribe(
        () => {
          const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
          if (returnUrl !== null) {
            this.router.navigate([returnUrl]);
          } else {
            this.router.navigate(['/']);
          }
        },
        error => {
          this.isLoading = false;
          this.notificationsService.error(error['error'].message);
          console.error(error);
        });

    }
  }
}
