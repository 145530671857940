import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { RecaptchaComponent as OrigRecaptchaComponent } from 'ng-recaptcha';
import { RecaptchaResolverService } from './recaptchaResolver.service';

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent {

  @Output() public resolved = new EventEmitter<string>();
  @Output() @ViewChild('captchaRef') public ref: OrigRecaptchaComponent;
  captchaResponse: string;

  constructor(
    public captcha: RecaptchaResolverService
  ) {

  }

  captchaResolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    this.emitResult();
  }

  execute(): void {
    this.ref.execute();
  }

  reset(): void {
    this.ref.reset();
  }

  private emitResult() {
    this.resolved.emit(this.captchaResponse);
  }

}
