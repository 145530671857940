import { Subject } from 'rxjs';
import { delay, share } from 'rxjs/operators';
import * as i0 from "@angular/core";
var LayoutService = /** @class */ (function () {
    function LayoutService() {
        this.layoutSize$ = new Subject();
    }
    LayoutService.prototype.changeLayoutSize = function () {
        this.layoutSize$.next();
    };
    LayoutService.prototype.onChangeLayoutSize = function () {
        return this.layoutSize$.pipe(share(), delay(1));
    };
    LayoutService.ngInjectableDef = i0.defineInjectable({ factory: function LayoutService_Factory() { return new LayoutService(); }, token: LayoutService, providedIn: "root" });
    return LayoutService;
}());
export { LayoutService };
