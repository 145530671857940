import { User } from '@/app/@core/model/user.model';
import UserResponse from '@/app/pages/users/model/user-response.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@app/@core/services/api/api.service';
import { CrudSingleService } from '@app/@core/services/crud/crud-single.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserMeService extends CrudSingleService {
  public user: UserResponse;

  private _companyUserRole: string;

  constructor(
    protected api: ApiService,
    private translate: TranslateService,
    private router: Router,
  ) {
    super(api);
    this._urlNameSpace = '/users/me';
    this.supportedMethods = ['get', 'update'];
  }

  clearCache() {
    this.user = undefined;
  }

  setLang(lang: string): Observable<User> {
    return new Observable(observer => {
      this.getUserInfo(true).subscribe((companyUser) => {
        const user = companyUser.user;
        user.lang = lang;

        this.api.put(this.urlNameSpace, user).subscribe((data) => {
          observer.next(data['data']);
        });
      });
    });
  }

  getUserInfo(fromCache: boolean = false): Observable<UserResponse> {
    if (typeof this.user === 'undefined' || fromCache === false) {
      return this.fetchUserInfo();
    }
    return new Observable(observer => {
      observer.next(this.user);
      observer.complete();
    });
  }

  getBranches(): Observable<any> {
    return new Observable(observer => {
      this.api.get('/branches/users/me').subscribe((data) => {
        observer.next(data['data'][0]);
        observer.complete();
      });
    });
  }

  private fetchUserInfo(): Observable<UserResponse> {
    return new Observable(observer => {
      const user$ = this.api.get(this.urlNameSpace);
      user$.subscribe(data => {
          let result;
          if (data['data']) {
            this.user = result = data['data'][0];

            this._companyUserRole = result.companyUser.role.name || null;
          }

          if (result['lang']) {
            this.translate.use(result['lang']);
          }

          observer.next(result);
          observer.complete();
        },
        error => {
        console.log({error, user: user$});
          if (error.code === 401 || error.code === 403) {
            this.router.navigate(['/login']);
          }
          observer.error(error);

        });
    });
  }

  get companyUserRole(): string {
    return this._companyUserRole;
  }


}
