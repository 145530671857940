/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-nav.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@nebular/theme/components/card/card.component.ngfactory";
import * as i3 from "@nebular/theme/components/card/card.component";
import * as i4 from "../../../../../node_modules/@nebular/theme/components/menu/menu.component.ngfactory";
import * as i5 from "@nebular/theme/components/menu/menu.component";
import * as i6 from "@nebular/theme/theme.options";
import * as i7 from "@nebular/theme/components/menu/menu.service";
import * as i8 from "@angular/router";
import * as i9 from "./side-nav.component";
import * as i10 from "../../../@core/services/feature-level/feature-level.service";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../../@core/services/auth/auth.service";
import * as i13 from "../../../@core/services/user-me/user-me.service";
import * as i14 from "@nebular/theme/components/sidebar/sidebar.service";
import * as i15 from "../../../@core/utils/layout.service";
var styles_SideNavComponent = [i0.styles];
var RenderType_SideNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideNavComponent, data: {} });
export { RenderType_SideNavComponent as RenderType_SideNavComponent };
export function View_SideNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "nb-card-body", [], null, null, null, i2.View_NbCardBodyComponent_0, i2.RenderType_NbCardBodyComponent)), i1.ɵdid(1, 49152, null, 0, i3.NbCardBodyComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "nb-menu", [["tag", "menu"]], [[2, "inverse", null]], null, null, i4.View_NbMenuComponent_0, i4.RenderType_NbMenuComponent)), i1.ɵdid(3, 4440064, null, 0, i5.NbMenuComponent, [i6.NB_WINDOW, i7.NbMenuInternalService, i8.Router], { tag: [0, "tag"], items: [1, "items"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "menu"; var currVal_2 = _co.menuItems; _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).inverseValue; _ck(_v, 2, 0, currVal_0); }); }
export function View_SideNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-nav", [], null, null, null, View_SideNavComponent_0, RenderType_SideNavComponent)), i1.ɵdid(1, 114688, null, 0, i9.SideNavComponent, [i8.Router, i10.FeatureLevelService, i11.TranslateService, i12.AuthService, i13.UserMeService, i14.NbSidebarService, i15.LayoutService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideNavComponentNgFactory = i1.ɵccf("app-side-nav", i9.SideNavComponent, View_SideNavComponent_Host_0, {}, {}, []);
export { SideNavComponentNgFactory as SideNavComponentNgFactory };
