import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { RegistrationModel } from '@app/pages/login/model/registration.model';
import { RegistrationService } from '@app/pages/login/services/registration.service';
import { RecaptchaComponent } from '@app/shared/components/recaptcha/recaptcha.component';
import { RecaptchaResolverService } from '@app/shared/components/recaptcha/recaptchaResolver.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent {

  submitted = false;
  alreadyInPOS = true;
  @ViewChild('captchaRef') captchaRef: RecaptchaComponent;

  constructor(
    private captcha: RecaptchaResolverService,
    private notify: NotificationsService,
    private router: Router,
    private service: RegistrationService,
    private translate: TranslateService
  ) {

  }

  validate(form: NgForm): boolean {
    const values: RegistrationModel = form.value;
    if (this.submitted) {
      this.notify.error(this.translate.instant('notif.formAlreadySubmitted'), this.translate.instant('notif.formAlreadySubmittedText'));
      return false;
    }
    if (values.password !== values.passwordAgain) {
      this.notify.error(this.translate.instant('page.forgotPassword.passwordCheckNotCorrect'));
      return false;
    }
    return true;
  }

  onSubmit(form: NgForm): void {
    const values: RegistrationModel = form.value;
    this.submitted = true;

    this.captchaRef.resolved.subscribe((captchaResolved) => {
      console.log('Captcha verified.');
      this.captchaRef.resolved.unsubscribe();
      values.captcha = captchaResolved;

      this.service.submit(values).subscribe(
        () => {
          this.captchaRef.reset();
          this.router.navigate(['/']);
          this.notify.success(
            this.translate.instant('notif.nowYouCanLogin')
          );
        },
        error => {
          this.captchaRef.reset();
          this.submitted = false;
          this.notify.error(error['error'].message);
          console.error(error);
        });
    });

    this.captchaRef.execute();
  }

}
