<nb-card (keydown.enter)="onSubmit()" class="login-form">
  <nb-card-header>
    <h2>{{'page.login.title' | translate}}</h2>
  </nb-card-header>
  <nb-card-body>
    <div *ngIf="!isLoading" class="edit-page__group">
      <input [(ngModel)]="model.username" fullWidth nbInput
             placeholder="{{'users.attribute.email' | translate}} / {{'users.attribute.username' | translate}}"
             type="email">

      <input [(ngModel)]="model.password" fullWidth nbInput placeholder="{{'users.attribute.password' | translate}}"
             type="password">

      <div class="text-center">
        <button (click)="onSubmit()" class="login-form__button" fullWidth hero nbButton status="success">
          {{'button.log_in' | translate}}
        </button>
      </div>
    </div>
    <app-loader *ngIf="isLoading"></app-loader>

    <br/>

    <div class="text-center">
      <button hero nbButton routerLink="/login/registration" status="primary">
        {{'button.register' | translate}}
      </button>
      &nbsp;
      <button hero
              matTooltip="{{'page.forgotPassword.tooltip' | translate}}" nbButton
              routerLink="/login/forgot-password"
              status="primary">
        {{'page.forgotPassword.title' | translate}}
      </button>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <app-change-language></app-change-language>
  </nb-card-footer>
</nb-card>
