import { Component } from '@angular/core';

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank.layout.html',
  styleUrls: ['./blank.layout.scss']
})
export class BlankLayoutComponent {

}
