var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractCrudService } from '@app/@core/services/crud/abstract-crud.service';
var CrudSingleService = /** @class */ (function (_super) {
    __extends(CrudSingleService, _super);
    function CrudSingleService(api) {
        var _this = _super.call(this, api) || this;
        _this.api = api;
        return _this;
    }
    CrudSingleService.prototype.get = function () {
        return this.callApiIfSupported('get', this.api.get(this._urlNameSpace));
    };
    CrudSingleService.prototype.update = function (entity) {
        return this.callApiIfSupported('update', this.api.put(this._urlNameSpace, entity));
    };
    CrudSingleService.prototype.remove = function () {
        return this.callApiIfSupported('remove', this.api.delete(this._urlNameSpace, {}));
    };
    return CrudSingleService;
}(AbstractCrudService));
export { CrudSingleService };
