<div class="forgot-password-finish-page">
  <div class="forgot-password-finish-page__form">
    <div class="action-buttons">
      <button hero nbButton routerLink="/login" status="success">
        <i class="fa fa-arrow-left"></i>&nbsp;
        {{'button.backToLogin' | translate}}</button>
    </div>
    <app-forgot-password-finish-form></app-forgot-password-finish-form>
  </div>
</div>
