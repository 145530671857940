import { Injectable } from '@angular/core';
import { ApiService } from '@app/@core/services/api/api.service';
import { RegistrationModel } from '@app/pages/login/model/registration.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private api: ApiService) {

  }

  submit(model: RegistrationModel): Observable<any> {
    return this.api.post('/users/register', {
      captcha: model.captcha,
      email: model.email,
      full_name: model.fullName,
      password: model.password,
      phone: model.phone,
      company_name: model.companyName,
      company_id: model.companyId,
      company_vat_id: model.companyVatId,
      company_street: model.companyStreet,
      company_city: model.companyCity,
      company_postal_code: model.companyPostalCode,
      company_country_abbrev: model.companyCountryAbbrev
    });
  }

}
